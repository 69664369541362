<template>
    <!-- (新增/编辑)领券明细 -->
    <div class="drawCouponCenterDetail">
        <!-- 顶部按钮 -->
           <topOperatingButton :disabled="form.billStatus == 2" @submitForm="submitForm('保存')" :showAudit="form.billStatus != 0"
      @addBill="submitForm('保存并新增')"  @auditBill="submitForm" @getQuit="getQuit" id="topOperatingButton">
    </topOperatingButton>
        <div class="content" v-loading="loading">
            <el-form label-width="90px" :model="form" :rules="rules" ref="form">
                <cardTitleCom
                    cardTitle="基础信息"
                    :billStatus="form.billStatus"
                >
                    <template slot="cardContent">
                        <div class="basicInfo">
                            <div class="x-f">
                                <el-form-item label="方案编号" prop="billNo">
                                    <el-input
                                        class="w400"
                                        v-model="form.billNo"
                                        disabled
                                        placeholder="自动生成"
                                    ></el-input>
                                </el-form-item>
                                <el-form-item label="方案名称" prop="billName">
                                    <el-input
                                        class="w400"
                                        v-model="form.billName"
                                        :disabled="form.billStatus == 2"
                                    ></el-input>
                                </el-form-item>
                            </div>
                            <el-form-item label="领券日期" prop="billDate">
                                <el-date-picker
                                    :disabled="form.billStatus == 2"
                                    class="w400"
                                    v-model="form.billDate"
                                    type="datetimerange"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    :picker-options="forbiddenTime"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    :default-time="['00:00:00', '23:59:59']"
                                >
                                </el-date-picker>
                            </el-form-item>
                        </div>
                    </template>
                </cardTitleCom>
                <cardTitleCom cardTitle="适用时间">
                    <template slot="cardContent">
                        <div class="couponContent">
                            <el-form-item label="适用时间" prop="useTimeRangeType">
                                <el-radio-group
                                    v-model="form.useTimeRangeType"
                                    :disabled="form.billStatus == 2"
                                >
                                    <el-radio
                                        :label="dict.value"
                                        v-for="dict in dict.type.use_time_range_type"
                                        :key="dict.value"
                                        :value="dict.value"
                                    >{{ dict.label }}
                                    </el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <div class="timeTable" v-if="form.useTimeRangeType === '2'">
                                <div class="btn x-f marB10">
                                    <el-button
                                        size="mini"
                                        @click="OpenDate = true"
                                        :disabled="form.billStatus == 2"
                                    >选择时间
                                    </el-button>
                                </div>
                                <!-- 时间表格 -->
                                <comTable
                                    :disabled="form.billStatus == 2"
                                    class="marB10"
                                    :columnLabel="timeTableLable"
                                    :tableData="form.mallMarketingCouponDrawSpecialDates"
                                    @delTableRow="delTableRow($event, 'time')"
                                ></comTable>
                            </div>
                        </div>
                    </template>
                </cardTitleCom>
                <cardTitleCom cardTitle="领券内容">
                    <template slot="cardContent">
                        <div class="couponContent">
                            <!-- @click="openCoupon = true" -->
                            <el-button
                                size="mini"
                                class="marB10"
                                @click="handleEvent('openCouponDialog')"
                                :disabled="form.billStatus == 2"
                            >选择优惠券
                            </el-button>
                            <!-- 时间表格 -->
                            <comTable
                                class="marB10"
                                :disabled="form.billStatus == 2"
                                :columnLabel="couponTableLable"
                                :tableData="form.mallMarketingCouponDrawCoupons"
                                @delTableRow="delTableRow($event, 'coupon')"
                            ></comTable>
                        </div>
                    </template>
                </cardTitleCom>
            </el-form>
        </div>
        <!-- 时间日期弹窗 -->
        <selectDate
            :OpenDate.sync="OpenDate"
            :form="form.specialDateItem"
            @getDateTimeList="getPitchData($event, 'time')"
        ></selectDate>
        <!-- 选择优惠券对话框 -->
        <selectCoupon
            :OpenCoupon.sync="openCoupon"
            :isRadio="true"
            :isSuingEditorIsRadio="true"
            isPage="drawCouponCenter"
            @getCoupons="getPitchData($event, 'coupon')"
        />
        <Dialog :options.sync="dialogOptions" @handleEvent="handleEvent" />
    </div>
</template>

<script>
import topOperatingButton from "@/views/components/topOperatingButton"; //顶部按钮
import Dialog from '@/components/Dialog'
import { getNewBillNo } from '@/utils/numFormatting' //获取单据编号
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import comTable from '@/views/components/com-table' //表格组件
import selectCoupon from '@/views/components/selectCoupon' //优惠券组件
import selectDate from '@/views/components/selectDate' //时间日期组件
import { uniqWith } from 'lodash' //去重
import {
    getDateChangeDetail //适用时间转换
} from '@/utils/public'
import {
    addDrawCoupon, //新增
    updateDrawCoupon, //修改
    getDetailDrawCoupon, //详情
    oneCarefulDrawCoupon //审核
} from '@/api/O2OMall/marketing/drawCouponCenter'

export default {
    name: 'DrawCouponCenterDetail',
    components: { cardTitleCom, comTable, selectDate, Dialog, selectCoupon ,topOperatingButton},
    dicts: ['use_time_range_type'],
    // 渲染该组件前调用这个路由钩子
    beforeRouteEnter(to, from, next) {
        //因为当守卫执行前,组件实例还没创建
        //一定要调用next()方法才能跳转到后续页面,否则不会解析导致无法进入页面
        //通过'vm'才能访问组件this实例
        next(vm => {
            if (to.query.type === 'update') {
                vm.close()
                vm.getDrawCouponDetail(to.query.billId)
            } else {
                vm.close()
                vm.getNewBillNo(vm.form, 'billNo', 180205)
            }
        })
    },
    data() {
        return {
            dialogOptions: {
                curType: '', //当前类型(门店/会员/优惠券)
                title: '选择会员',
                width: 1250,
                show: false,
                type: 'TreeAndTable'
            },
            loading: false, //遮罩层
            form: {
                billId: undefined, //单据id
                billNo: undefined, //方案编号
                billName: undefined, //方案名称
                billDate: undefined, //单据日期
                begTime: undefined, //开始时间
                endTime: undefined, //结束时间
                useTimeRangeType: '0', //适用时间
                mallMarketingCouponDrawSpecialDates: [], //特殊日期时间
                mallMarketingCouponDrawCoupons: [] //优惠券
            },
            //时间范围表格字段
            timeTableLable: [{ label: '时间范围', prop: 'dateTime' }],
            //优惠券表格字段
            couponTableLable: [
                { label: '优惠券名称', prop: 'couponCaseName' },
                { label: '限领张数(单个会员)', prop: 'couponDrawModeName' },
                { label: '投放数量', prop: 'couponEmitQty' },
                { label: '生效方式', prop: 'couponEffectModeName' }
            ],
            //选择时间日期开关
            OpenDate: false,
            //选择优惠券开关
            openCoupon: false,
            // 禁用当天之前的时间
            forbiddenTime: {
                //禁用当前日期之前的日期
                disabledDate(time) {
                    //Date.now()是javascript中的内置函数，它返回自1970年1月1日00:00:00 UTC以来经过的毫秒数。
                    return time.getTime() < Date.now() - 8.64e7
                }
            },
            // 表单校验
            rules: {
                billName: [
                    { required: true, message: '请输入方案名称', trigger: 'blur' }
                ],
                billNo: [
                    { required: true, message: '请输入方案编号', trigger: 'blur' }
                ],
                billDate: [
                    { required: true, message: '请输入领券时间', trigger: 'blur' }
                ],
                useTimeRangeType: [
                    { required: true, message: '请选择适用时间', trigger: 'blur' }
                ]
            }
        }
    },
    methods: {
        async handleEvent (type, row) {
            console.log(type, row, 'row')
            switch (type) {
                case 'openCouponDialog':
                //弹窗配置
                this.dialogOptions = {
                    curTable: 'couponOptions',
                    curType: 'couponCaseId', //当前类型(会员/门店/优惠券)
                    title: '选择优惠券',
                    width: 1250,
                    show: true,
                    type: 'TreeAndTable',
                    isQuantity: true,
                    isStep: true,
                    isGrant: false,
                    isNumber: true,
                    isText: true,
                    formData: this.$dialog({ key: 'coupon' })
                }
                this.dialogOptions.formData.table.radioSelect = true
                this.dialogOptions.formData.table.mutiSelect = false
                break
                case 'dialogChange':
                let list = []
                let curTable = this.dialogOptions.curTable
                let curType = this.dialogOptions.curType
                if(curType != 'timeDate'){
                    if (this.dialogOptions.formData.table.check.length <= 0) return
                    list = this.dialogOptions.formData.table.check
                    
                    list = [...list, ...this.form.mallMarketingCouponDrawCoupons]
                    list = uniqWith(list, (x, y) => x[curType] == y[curType])
                    const couponDrawModes = {
                        0: '每天',
                        1: '每周',
                        2: '每月',
                        3: '总共'
                    }
                    list = list.map(item => ({
                        ...item,
                        couponDrawModeName: `${couponDrawModes[item.couponDrawMode]}${item.couponEveryDrawQty}张`
                    }))
                }
                this.form.mallMarketingCouponDrawCoupons = list
                break
                default:
                break
            }
        },
        //适用时间转换
        getDateChangeDetail,
        //新增时获取单据编号
        getNewBillNo,
        //删除表格行事件(共同删除事件,以type区分表格(时间/优惠券)
        delTableRow(row, type) {
            if (type === 'time') {
                //时间
                //过滤掉删除的数据行
                this.form.mallMarketingCouponDrawSpecialDates =
                    this.form.mallMarketingCouponDrawSpecialDates.filter(
                        v => v.id !== row.id
                    )
            } else if (type === 'coupon') {
                //优惠券
                //过滤掉删除的数据行
                this.form.mallMarketingCouponDrawCoupons =
                    this.form.mallMarketingCouponDrawCoupons.filter(
                        v => v.couponCaseId !== row.couponCaseId
                    )
            }
        },
        //获取选中的数据赋值表格    type区分类型(时间/优惠券)
        async getPitchData(data, type) {
            if (type === 'time') {
                let tableData = await this.getDateChangeDetail(data) //日期时间转换
                tableData.forEach(item => {
                    this.form.mallMarketingCouponDrawSpecialDates.push(item)
                })
                return
            } else if (type === 'coupon') {
                //组装数据
                let assemblyCouponObj = this.assemblyCoupon(data[0])
                this.form.mallMarketingCouponDrawCoupons.push({
                    ...data[0],
                    ...assemblyCouponObj
                })
            }
        },
        //组装优惠券数据转换
        assemblyCoupon(value) {
            //限领张数
            const couponDrawModes = {
                0: '每天',
                1: '每周',
                2: '每月',
                3: '总共'
            }
            const { couponDrawMode, couponEveryDrawQty } = value
            let couponDrawModeName = (couponDrawModes[
                couponDrawMode
                ] += `${couponEveryDrawQty}张`)
            //生效方式
            const couponEffectModes = {
                0: '即时生效， ',
                1: '延迟生效， ',
                2: '固定日期生效， '
            }
            const {
                couponEffectMode,
                couponEffectDays,
                couponEffectDate,
                couponValidDays
            } = value
            let couponEffectModeName = couponEffectModes[couponEffectMode]
            if (couponEffectMode === 0) {
                couponEffectModeName += `生效后有效时长${couponValidDays}天`
            } else if (couponEffectMode === 1) {
                couponEffectModeName += `领取${couponEffectDays}天后生效,生效后有效时长${couponValidDays}天`
            } else if (couponEffectMode === 2) {
                couponEffectModeName += `${couponEffectDate},生效后有效时长${couponValidDays}天`
            }
            value.couponDrawModeName = couponDrawModeName
            value.couponEffectModeName = couponEffectModeName
            return value
        },
        //领券方案详情
        async getDrawCouponDetail(billId) {
            this.loading = true
            try {
                let res = await getDetailDrawCoupon(billId)
                this.dataConvertAssembly(res) //后端数据转换显示
                this.form = res.data
                this.loading = false
            } catch {
                this.loading = false
            }
        },

        //提交表单
        submitForm(type) {
            this.$refs['form'].validate(async valid => {
                if (valid) {
                    //校验
                    if (
                        this.form.useTimeRangeType === '2' &&
                        this.form.mallMarketingCouponDrawSpecialDates.length === 0
                    ) {
                        return this.$message.error('请选择适用时间')
                    }
                    if (this.form.mallMarketingCouponDrawCoupons.length === 0) {
                        return this.$message.error('请选择优惠券')
                    }
                    this.form.begTime = this.form.billDate[0]
                    this.form.endTime = this.form.billDate[1]
                    if (type === '审核') {
                        this.form.billStatus = '2'
                    } else if (type === '反审核') {
                        this.form.billStatus = '0'
                    }
                    if (this.form.billId === undefined) {
                        //新增
                        try {
                            let res = await addDrawCoupon(this.form)
                            this.dataConvertAssembly(res) //后端数据转换显示
                            this.form = res.data
                            this.$message.success('操作成功')
                            // 是否新增
                            if (type === '保存并新增') {
                                this.close()
                                this.getNewBillNo(this.form, 'billNo', 180205)
                            } else if (type === '保存') {
                                // this.$router.push('/O2OMall/marketing/drawCouponCenter')
                            }
                        } catch {
                        }
                    } else {
                        //修改
                        let res = await updateDrawCoupon(this.form)
                        this.dataConvertAssembly(res) //后端数据转换显示
                        this.form = res.data
                        this.$message.success('操作成功')
                        // 是否新增
                        if (type === '保存并新增') {
                            this.close()
                            this.getNewBillNo(this.form, 'billNo', 180205)
                        } else if (type === '保存') {
                            // this.$router.push('/O2OMall/marketing/drawCouponCenter')
                        }
                    }
                }
            })
        },
        //后端数据转换显示
        async dataConvertAssembly(res) {
            if (!res.data.mallMarketingCouponDrawSpecialDates) {
                res.data.mallMarketingCouponDrawSpecialDates = []
            }
            if (!res.data.mallMarketingCouponDrawCoupons) {
                res.data.mallMarketingCouponDrawCoupons = []
            }
            let dateTimeList = await this.getDateChangeDetail(
                res.data.mallMarketingCouponDrawSpecialDates
            ) //日期时间转换
            res.data.mallMarketingCouponDrawSpecialDates = dateTimeList
            res.data.mallMarketingCouponDrawCoupons =
                res.data.mallMarketingCouponDrawCoupons.map(item => {
                    item = this.assemblyCoupon(item) //优惠券转换
                    return item
                })
            res.data.billDate = []
            res.data.billDate[0] = res.data.begTime
            res.data.billDate[1] = res.data.endTime
        },
        //退出按钮
        getQuit() {
            this.$tab.closeOpenPage({ path: "/O2OMall/marketing/drawCouponCenter" });
        },
        //初始化数据
        close() {
            this.form = {
                billId: undefined, //单据id
                billNo: undefined, //方案编号
                billName: undefined, //方案名称
                billDate: undefined, //单据日期
                begTime: undefined, //开始时间
                endTime: undefined, //结束时间
                useTimeRangeType: '0', //适用时间
                mallMarketingCouponDrawSpecialDates: [], //特殊日期时间
                mallMarketingCouponDrawCoupons: [] //优惠券
            }
            this.resetForm('form')
        }
    }
}
</script>

<style lang="scss" scoped>
.drawCouponCenterDetail {
    width: 100%;
    padding: 6px;
    padding-top: 1px;
    background-color: #eaeaea;

    //内容区域
    .content {
        margin-top: 35px;
    }

    //基础信息
    .basicInfo {
        padding: 10px;

        .w400 {
            width: 400px;
        }
    }

    //领券内容
    .couponContent {
        padding: 10px;
        //每一行的间距
        .el-form-item {
            margin-bottom: 10px;
        }
    }
}
</style>
