<template>
  <!-- 邀请有礼明细 -->
  <div class="wrap" v-loading="loading" id="wrap" ref="wrap">
    <el-form ref="form" :model="couponOptions" :rules="rules" label-width="150px">
      <!-- :isExamineBtn="![2 , '2'].includes(couponOptions.billStatus)" -->
      <topOperatingButton
        :showAudit="isShowAudit"
        :isStopBtn="false"
        :isSubmitAddBtn="false"
        :isAuditBillBtn="false"
        :isExamineBtn="true"
        :disabled='disableOperate'
        id="topOperatingButton"
        ref="topOperatingButton"
        @submitForm="submitForm(false)"
        @addBill="submitForm(true)"
        @auditBill="auditForm"
        @getQuit="getQuit"
      >
      </topOperatingButton>

      <cardTitleCom
        cardTitle="基本信息"
        id="basicInformation"
      >
        <template slot="cardContent">
          <div class="x-w marT10">
            <el-form-item label="活动编号" prop="billNo">
              <el-input
                class="inputWidth"
                size="mini"
                :disabled="true"
                v-model="couponOptions.billNo"
                placeholder="活动编号"
              ></el-input>
            </el-form-item>
            <el-form-item label="活动名称" prop="billName">
              <el-input
                class="inputWidth"
                size="mini"
                :disabled="disableOperate"
                v-model="couponOptions.billName"
                placeholder="活动名称"
                maxlength="40"
              ></el-input>
            </el-form-item>
            <el-form-item label="活动日期" prop="datetime">
              <el-date-picker
                size="mini"
                v-model="couponOptions.datetime"
                type="datetimerange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
                value-format="yyyy-MM-dd HH:mm:ss"
                :disabled="disableOperate"
                :picker-options="forbiddenTime"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="方案说明" prop="billRemark">
              <el-input
                size="mini"
                class="inputWidth"
                v-model="couponOptions.billRemark"
                type="textarea"
                placeholder="方案说明长度介于 1 和 200 字符之间"
                maxlength="200"
                :autosize="{ minRows: 2, maxRows: 2 }"
                :disabled="disableOperate"
              />
            </el-form-item>
          </div>
        </template>
      </cardTitleCom>

      <cardTitleCom
        cardTitle="活动优惠券"
        id="basicInformation">
        <template slot="cardContent">
          <div class=" marT10 mar20">
            <div class="mt20">
              <el-radio @input="radioInpit" :disabled="disableOperate" v-model="couponOptions.caseFissionType"  :label="1">定额优惠券</el-radio>
              <el-radio @input="radioInpit" :disabled="disableOperate" v-model="couponOptions.caseFissionType" :label="2">凭手气优惠券</el-radio>
            </div>
            <el-button :disabled="disableOperate" class="mb10" size="mini"
                @click="handleEvent('openCouponDialog')">选择优惠券</el-button>
            <EditTable style="width: 100%;" :options="couponOptions"  @handleEvent="handleEvent" />
            <!-- input: 'nowWriteOffMoney', -->
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="活动设置">
        <template slot="cardContent">
          <div class="giveRule">
            <div class="multiplyTitle">
              <el-form-item label="优惠券数量" :required="true">
                <div class="RuleListdetail">
                  <el-form-item label="" prop="limitCouponQty">
                    <el-input :disabled="disableOperate" size="mini" class="fillRuleinputMoney"
                     oninput="if (value > 99999999) value = 99999999; value=value.replace(/^0|[^0-9]/g,'')"
                    v-model="couponOptions.limitCouponQty"
                    ></el-input>
                    <!-- @input="limitInputlength(couponOptions.limitCouponQty, couponOptions,'limitCouponQty', true, 2, false)"   -->
                  </el-form-item>
                </div>
              </el-form-item>
              <el-form-item label="第N个领取金额最大" v-if="couponOptions.caseFissionType == 2" :required="true">
                <div class="RuleListdetail">
                  <el-select :disabled="disableOperate"  v-model="couponOptions.maxMoneyNth" size="mini" placeholder="请选择">
                    <el-option v-for="(item, index) of 10" :key="index" :value="item"></el-option>
                  </el-select>
                  <el-tooltip style="color: black;margin-left: 10px;" content="指每个裂变券礼包所包含的优惠券数量。举例：数量设置为10，则每笔订单送出去的裂变券礼包里含有10张优惠券，最多可有10位用户分别领取一张。" placement="top">
                    <i class="el-icon-question"></i>
                  </el-tooltip>
                </div>
              </el-form-item>
              <el-form-item label="金额最大优惠券" v-if="couponOptions.caseFissionType == 2" :required="true">
                <div class="RuleListdetail">
                  <el-select :disabled="disableOperate"  v-model="couponOptions.maxMoneyCouponCaseId" size="mini" placeholder="请选择">
                    <el-option v-for="(item, index) of couponOptions.list" :label="item.couponCaseName" :key="index" :value="item.couponCaseId"></el-option>
                  </el-select>
                </div>
              </el-form-item>
              <el-form-item label="活动可发起总量" :required="true">
                <div class="RuleListdetail">
                  <el-form-item label="" prop="caseUsableTotal">
                    <el-input :disabled="disableOperate" size="mini" class="fillRuleinputMoney"
                    oninput="if (value > 99999999) value = 99999999; value=value.replace(/^0|[^0-9]/g,'')"
                    v-model="couponOptions.caseUsableTotal"
                    :max="99999999"></el-input>
                    <!-- @input="limitInputlength(couponOptions.caseUsableTotal, couponOptions,'caseUsableTotal', true, 2, false)"   -->
                  </el-form-item>
                </div>
              </el-form-item>
              <el-form-item label="领取次数限制" :required="true">
                <div class="RuleListdetail">
                  <el-radio :disabled="disableOperate" v-model="couponOptions.drawTimesType" :label="0">不限制</el-radio>
                </div>
              </el-form-item>
              <el-form-item label="" >
                <div class="RuleListdetail">
                  <el-radio :disabled="disableOperate" v-model="couponOptions.drawTimesType" :label="1">每人活动期间最多领取</el-radio>
                  <div v-if="[1].includes(couponOptions.drawTimesType)"  class="x-f" >
                    <el-form-item label="" prop="everyOneTotalTimes">
                      <el-input style="width: 200px;" class="fillRuleinputMoney" 
                        :disabled='disableOperate'
                        oninput="if (value > 9999) value = 9999; value=value.replace(/^0|[^0-9]/g,'')"
                        v-model="couponOptions.everyOneTotalTimes">
                        <template slot="append">
                          <el-button>次</el-button>
                        </template>
                        <!-- @input="limitInputlength(couponOptions.everyOneTotalTimes, couponOptions,'everyOneTotalTimes', true, 2, false)" size="mini" -->
                      </el-input>
                    </el-form-item>
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="" >
                <div class="RuleListdetail">
                  <el-radio :disabled="disableOperate" v-model="couponOptions.drawTimesType" :label="2">每人每天最多领取</el-radio>
                  <div v-if="[2].includes(couponOptions.drawTimesType)"  class="x-f" >
                    <el-form-item label="" prop="everyOneDayTimes">
                      <el-input style="width: 200px;" class="fillRuleinputMoney" 
                        :disabled='disableOperate'
                       oninput="if (value > 9999) value = 9999; value=value.replace(/^0|[^0-9]/g,'')"
                        v-model="couponOptions.everyOneDayTimes">
                        <template slot="append">
                          <el-button>次</el-button>
                        </template>
                      </el-input>
                      <!-- @input="limitInputlength(couponOptions.everyOneDayTimes, couponOptions,'everyOneDayTimes', true, 2, false)" size="mini" -->
                    </el-form-item>
                  </div>
                </div>
              </el-form-item>
            </div>
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="拓展设置">
        <template slot="cardContent">
          <div class="giveRule">
            <div class="multiplyTitle">
              <el-form-item label="活动分享卡文案" >
                  <div class="RuleListdetail">
                    <el-input
                      size="mini"
                      class="inputWidth"
                      v-model="couponOptions.shareCardRecordActivitiesTitle"
                      type="textarea"
                      placeholder="请输入文案"
                      maxlength="200"
                      :autosize="{ minRows: 1, maxRows: 1 }"
                      :disabled="disableOperate"
                    />
                  </div>
                  <div class="text">不填写则展示默认文案"快来试试手气! 点下就能领到手, 速抢!"</div>
              </el-form-item>
              <el-form-item label="活动分享卡设置" :required="true">
                  <div class="">
                    <el-radio :disabled='disableOperate' v-model="couponOptions.shareCardType" :label="1">默认分享卡</el-radio>
                    <div><img style="vertical-align: text-top;width: 100px;height: 100px;" :src="shareUrL" alt="加载失败" /></div>
                  </div>
                  <div class="">
                    <el-radio :disabled='disableOperate' v-model="couponOptions.shareCardType" :label="2">自定义分享卡</el-radio>
                    <AccessoryUpload listType="picture-card" 
                      size="mini" title="" :limit="1" :fileList="fileList"
                                @getFileItems="getFileItems" 
                                :dialogImageUrl="couponOptions.posterImageUrl" 
                                @delFileItems="deleteImg" :noneBtnImg="couponOptions.posterImageUrl != ''" :fileSize="1" accept='.JPG,.PNG,.JPEG'></AccessoryUpload>
                    <!-- <span>不填写则展示默认文案"快来试试手气! 点下就能领到手, 速抢!"</span> -->
                  </div>
              </el-form-item>
              <el-form-item label="退款设置:">
                <div class="RuleListdetail">
                  <el-checkbox :disabled='disableOperate' v-model="couponOptions.isOrderRefundCouponInvalid">订单退款时优惠券失效</el-checkbox>
                  <span class="ml20 text">勾选后，若分享者的订单退款，所有通过该订单分享出来的优惠券将不可继续领取已领取未使用的优惠券也将失效。</span>
                </div>
              </el-form-item>
            </div>
          </div>
        </template>
      </cardTitleCom>
      <Dialog :options.sync="dialogOptions" @handleEvent="handleEvent" />
    </el-form>
    
  </div>
</template>

<script>
import topOperatingButton from '@/views/components/topOperatingButton' //顶部按钮
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import EditTable from '@/components/editTable' //可编辑表格
import Dialog from '@/components/Dialog'
import { uniqWith } from 'lodash' //去重
import { limitInputlength, fcount } from '@/utils'
import AccessoryUpload from '@/views/components/accessoryUpload/index.vue' //上传图片/附件组件
import { getBillNo } from '@/api/codeRule'

import {
  couponFissionSave,
  couponFissionDetail,
  couponFissionUpdate,
  couponFissionAudit,
} from '@/api/O2OMall/marketing/couponFission'
export default {
  name: 'CouponFissionDetail',
  components: {
    topOperatingButton,
    cardTitleCom,
    EditTable,
    Dialog,
    AccessoryUpload,
  },
  watch: {
    'couponOptions.billStatus': {
      handler (val) {
          this.disableOperate = ['2', '3', '4', 2, 3, 4].includes(val)
          this.isShowAudit = ['', 4, '4'].includes(val)
          this.couponOptions.columns[4].disabled = this.disableOperate
          // this.couponOptions.columns[6].disabled = this.disableOperate
          this.couponOptions.status = this.disableOperate
      },
      immediate: true
    },
  },
  data () {
    return {
       forbiddenTime: {
        //禁用当前日期之前的日期
        disabledDate(time) {
          //Date.now()是javascript中的内置函数，它返回自1970年1月1日00:00:00 UTC以来经过的毫秒数。
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      shareUrL: 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-04-23/bcd412d8f9d643f488d7b469906de501.png',
      radio1: '3',
      checked: true,
     
      optionsValue: '',
      value: '',
      fileList: [],
      loading: false,
      radio: '1',
      clickItem: 0, //当前焦点下标
      disableOperate: false,
      isShowAudit: false,
      //弹窗配置
      dialogOptions: {
        curType: '', //当前类型(门店/会员/优惠券)
        title: '选择会员',
        width: 1250,
        show: false,
        type: 'TreeAndTable'
      },
      rules: {
        billNo: [
          {
            required: true,
            message: '请输入方案编号',
            trigger: ['blur', 'change']
          }
        ],
        billName: [
          { required: true, message: '请输入活动名称', trigger: 'blur' }
        ],
        datetime: [
          { type: 'array', required: true, message: '请选择时间', trigger: 'change' }
        ],

        caseUsableTotal: [
          { required: true, message: '请输入活动可发起总量', trigger: 'blur' }
        ],
        limitCouponQty: [
          { required: true, message: '请输入优惠券数量', trigger: 'blur' }
        ],

        everyOneTotalTimes: [
          { required: true, message: '请输入每人活动期间最多可发起数', trigger: 'blur' }
        ],
        everyOneDayTimes: [
          { required: true, message: '请输入每人每天最多可发起数', trigger: 'blur' }
        ],
        
      },
      couponOptions: {
        posterImageUrl: '',
        billNo: undefined,
        billId: undefined,
        billName: undefined,
        datetime: undefined,
        billRemark: undefined,
        
        caseUsableTotal: undefined,
        shareCardType: 1,
        caseFissionType: 1,

        drawTimesType: 0,
        everyOneTotalTimes: undefined,
        everyOneDayTimes: undefined,

        shareCardRecordActivitiesTitle: undefined,
        
        isOrderRefundCouponInvalid: true,
        
        billStatus: '',
        tableIndex: 0,
        retainColumn: true,
        curListIndex: -1, //当前表格行
        mutiSelect: false, //是否多选
        list: [],
        curListIndex: -1, //当前表格下标
        tableCellLabel: '序号',
        // tableHeight: 200, //表格高度
        status: undefined, //单据状态
        isNoAddBtn: true, //不显示表格增加
        hideTotal: true,
        columns: [
          {
            prop: 'couponCaseNo',
            label: '优惠券编号',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'couponCaseName',
            label: '优惠券名称',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'couponEmitModeName',
            label: '赠送方式',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'couponEffectModeName',
            label: '生效方式',
            minWidth: 120,
            align: 'center'
          },
        ]
      },
    }
  },
  methods: {
          //退出
    getQuit() {
      // 关闭当前tab页签，打开新页签
      this.$tab.closeOpenPage({ path: "/O2OMall/marketing/couponFission" });
    },
    radioInpit (val) {
      let arr = [
          {
            prop: 'couponCaseNo',
            label: '优惠券编号',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'couponCaseName',
            label: '优惠券名称',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'couponEmitModeName',
            label: '赠送方式',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'couponEffectModeName',
            label: '生效方式',
            minWidth: 120,
            align: 'center'
          },
          
        ]
      if(val == 1) {
        this.couponOptions.columns = arr
      } else {
        arr.push({
          prop: 'couponRatioQty',
          label: '数量配比',
          minWidth: 120,
          align: 'center',
          type: 'input',
          disabled: false,
          rules: true,
          isMinus: true,
          input: 'couponRatioQty',
        })
        this.couponOptions.columns = arr
      }
    },
    limitInputlength,
    async getBillno () {
      this.couponOptions.billNo = await getBillNo(180207)
    },
    async getDetail () {
      // this.reset()
      if (this.$route.query.type === 'Update' && this.$route.query.billId) {
        this.loading = true
        try {
          const { data } = await couponFissionDetail({billId: this.$route.query.billId})
          this.couponOptions = {
            ...this.couponOptions,
            ...data,
            list: [],
            datetime: [data.begTime, data.endTime],
          }
          
          this.couponOptions.list = this.couponOptions.o2omallMarketingCouponFissionCaseCouponList.map((item) => (
              {
                  ...item,
                  couponCaseId: item.largessCouponCaseId,
                  largessCouponCaseId: item.largessCouponCaseId,
                  couponCaseNo: item.largessCouponCaseNo,
                  couponCaseName: item.largessCouponCaseName,
                  couponEmitModeName:item.couponEffectMode == 0? '即时生效': item.couponEffectMode == 1? '延迟生效' : '固定日期生效',
                  couponEffectModeName: item.couponEffectMode == 0? `即时生效， 生效后有效时长${item.couponValidDays}` :
                    item.couponEffectMode == 1? `延迟生效， 领取${item.couponEffectDays}天后生效,生效后有效时长${item.couponValidDays}天` 
                    : `固定日期生效， ${item.couponEffectDate},生效后有效时长${item.couponValidDays}天` 
              }
          ))
          
          if (this.couponOptions.shareCardType == 2) {
            this.fileList = [
              {url : this.couponOptions.shareCardImageUrl}
            ]
            this.couponOptions.posterImageUrl = this.couponOptions.shareCardImageUrl? this.couponOptions.shareCardImageUrl : ''
          } else {
            this.couponOptions.posterImageUrl = ''
          }

          if (this.couponOptions.caseFissionType == 2) {
            this.couponOptions.columns= [
              {
                prop: 'couponCaseNo',
                label: '优惠券编号',
                minWidth: 120,
                align: 'center'
              },
              {
                prop: 'couponCaseName',
                label: '优惠券名称',
                minWidth: 120,
                align: 'center'
              },
              {
                prop: 'couponEmitModeName',
                label: '赠送方式',
                minWidth: 120,
                align: 'center'
              },
              {
                prop: 'couponEffectModeName',
                label: '生效方式',
                minWidth: 120,
                align: 'center'
              },
              {
                prop: 'couponRatioQty',
                label: '数量配比',
                minWidth: 120,
                align: 'center',
                type: 'input',
                disabled: false,
                rules: true,
                isMinus: true,
              }
            ]
          }
        } catch (err) {}
        this.loading = false
      } else {
        // this.reset()
        this.getBillno()
      }
    },
    getFileItems(file){
      this.couponOptions.posterImageUrl = file.accessUrl
    },
    deleteImg(){
      this.couponOptions.posterImageUrl = ''
    },
    async handleEvent (type, row) {
      let contains = function (list, row) {
        var i = list.length
        while (i--) {
          if (list[i] === row) {
            return i
          }
        }
        return false
      }
      switch (type) {
        case 'couponRatioQty':
          let index = contains(this.couponOptions.list, row)
          this.couponOptions.list[index].couponRatioQty = this.couponOptions.list[index].couponRatioQty.replace(/^0|[^0-9]/g,'')
          if (this.couponOptions.list[index].couponRatioQty == 0) {
            this.$set(this.couponOptions.list[index], 'couponRatioQty', '')
          }
          break
        case 'openCouponDialog':
          //弹窗配置
          this.dialogOptions = {
            curTable: 'couponOptions',
            curType: 'couponCaseId', //当前类型(会员/门店/优惠券)
            title: '选择类别',
            width: 1250,
            show: true,
            type: 'TreeAndTable',
            isStep: true,
            isGrant: false,
            isNumber: false,
            formData: this.$dialog({ key: 'coupon' })
          }
          this.dialogOptions.formData.table.radioSelect = true
          this.dialogOptions.formData.table.mutiSelect = false
          break
        case 'openTimeDateDialog':
           //弹窗配置
           this.dialogOptions = {
            curTable: 'dateOptions',
            curType: 'timeDate', 
            title: '选择时间日期',
            width: 600,
            show: true,
            type: 'TimeDateSelect',
            formData: {
              DateTimeList: this.dateOptions.echoList // 日期
            }
          }
          break
        case 'dialogChange':
          let list = []
          let curTable = this.dialogOptions.curTable
          let curType = this.dialogOptions.curType

          if(curType != 'timeDate'){
            if (this.dialogOptions.formData.table.check.length <= 0) return
            list = this.dialogOptions.formData.table.check
            list = [...list, ...this[curTable].list]
            list = uniqWith(list, (x, y) => x[curType] == y[curType])
            if (curType == 'couponCaseId')
              list = list.map(item => ({
                ...item,
                largessCouponCaseId: item.couponCaseId
            }))
          }else{
            this[curTable].echoList = this.dialogOptions.formData.DateTimeList //方便适用时间回显
            list = await getDateChangeDetail(
              this.dialogOptions.formData.DateTimeList  || []
            )
          }

          this[curTable].list = list
          break
        default:
          break
      }
    },
    async submitForm (isAdd) {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          if (this.couponOptions.caseFissionType == 2) {
            let couponRatioQtyTotal = 0; // 本次收款统计
            couponRatioQtyTotal = this.couponOptions.list.reduce((prev, next) => {
              return fcount([prev, next.couponRatioQty],'+');
            }, 0);
            console.log(couponRatioQtyTotal, 'couponRatioQtyTotal');
            console.log(couponRatioQtyTotal, 'couponRatioQtyTotal');
            if (this.couponOptions.limitCouponQty != couponRatioQtyTotal) {
              this.$message.warning('优惠券数量配比总数不等于优惠券数量')
              return
            }
          }
          // let sourceIndex = this.couponOptions.list.findIndex(item => !item.couponRatioQty || !item.carveUpCouponCaseId )
          if (this.couponOptions.list.length == 0) return this.$message.error('请录入完整的优惠券信息')
          let form = {
            billNo: this.couponOptions.billNo,
            billId: this.couponOptions.billId,
            billName: this.couponOptions.billName,
            begTime: this.couponOptions.datetime[0],
            endTime: this.couponOptions.datetime[1],
            billRemark: this.couponOptions.billRemark,

            caseFissionType: this.couponOptions.caseFissionType,
            caseUsableTotal: this.couponOptions.caseUsableTotal,
            maxMoneyCouponCaseId: this.couponOptions.maxMoneyCouponCaseId,
            maxMoneyNth: this.couponOptions.maxMoneyNth,
            limitCouponQty: this.couponOptions.limitCouponQty,

            drawTimesType: this.couponOptions.drawTimesType,
            everyOneTotalTimes: this.couponOptions.everyOneTotalTimes,
            everyOneDayTimes: this.couponOptions.everyOneDayTimes,
            
            shareCardRecordActivitiesTitle: this.couponOptions.shareCardRecordActivitiesTitle,
            
            shareCardType: this.couponOptions.shareCardType,

            isOrderRefundCouponInvalid: this.couponOptions.isOrderRefundCouponInvalid,

            o2omallMarketingCouponFissionCaseCouponList: this.couponOptions.list,
          }

          if (this.couponOptions.shareCardType == 2 && !this.couponOptions.posterImageUrl) {
            return this.$message.warning('请上传分享图片')
          } else {
            form.posterImageUrl = this.couponOptions.shareCardType == 1?  this.shareUrL : this.couponOptions.posterImageUrl
          }
          form.shareCardImageUrl = form.posterImageUrl
          
          if (form.billId) {
            couponFissionUpdate(form).then((res) => {
              this.$message.success('修改成功')
              this.loading = false
            }).catch((err) => {
              this.loading = false
            })
          } else {
            couponFissionSave(form).then((res) => {
              this.$message.success('新增成功')
              this.couponOptions.billId = res.data
              this.loading = false
              return true
            }).catch((err) => {
              this.loading = false
              return true
            })
          }
        } else {
          this.$message.warning('请输入必填项')
          return false
        }
      })
    },
    async auditForm (type) {
      if ([2, '2'].includes(this.couponOptions.billStatus) && type == '审核') return this.$message.warning('此单据不是未审核的状态哦~')
      if (['审核', '反审核'].includes(type)) {
        await this.$confirm(`确定${type}该方案`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        this.loading = true
        const { data } = await couponFissionAudit({
          billIds: [this.couponOptions.billId],
          auditStatus: type == '审核'? 2 : 0
        })
        this.couponOptions.billStatus = 2
        this.$message.success(`${type}成功`)
        this.loading = false
      }
    },
  },
  activated () {
    this.getDetail()
  },
  mounted () {
    // this.getDetail()
  },
}
</script>

<style lang="scss" scoped>
.wrap {
  padding: 40px 10px 10px 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  overflow-y: scroll;
  //附件
  .accessoryUpload {
    margin: 6px 10px;
    min-height: 150px;
    width: 200px;
    height: 200px;
  }
  .el-upload--picture-card{
    width: 200px;
    height: 200px;
  }
  ::v-deep .el-upload--picture-card {
    width: 100px;
    height: 100px;
  }
}
.table {
  padding: 10px;
}
.leftContent {
  .text {
    width: 70px;
    font-size: 14px;
    line-height: 40px;
  }
  .el-icon-question {
    margin-top: 10px;
  }
  ::v-deep .el-input--medium {
    width: 140px;
  }
  ::v-deep .el-input__inner {
    border-radius: 0;
    border: none;
    border-bottom: 2px solid #ccc;
  }
}
  //充值规则
.giveRule {
  padding: 0 20px;
  .multiplyTitle {
    padding: 20px 0 10px 0;
    .checkboxTitle {
      margin-left: 7px;
      font-size: 14px;
    }
  }
  //充值规则列表
  .fillRuleListTable {
    display: flex;
    cursor: pointer;
    .fillRuleList {
      padding: 7px;
      width: 200px;
      height: 120px;
      font-size: 14px;
      margin-right: 24px;
      visibility: visible;
      border: 1px solid #e6e6e6;
      .fillRuleListAdd {
        color: #2386ee;
        display: flex;
        flex-direction: row;
        line-height: 110px;
        align-items: center;
        justify-content: center;
      }
      //删除图标
      .fillRuleListMoney {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    //充值规则列表获得焦点
    .focusfillRuleList {
      position: relative;
      padding: 7px;
      width: 200px;
      height: 120px;
      font-size: 14px;
      margin-right: 24px;
      visibility: visible;
      border: 1px solid #017ee1;

      &::before {
        position: absolute;
        content: '';
        right: 0;
        bottom: 0;
        border-right: 13px solid #2386ee;
        border-left: 13px solid transparent;
        border-top: 11px solid transparent;
        border-bottom: 11px solid #2386ee;
      }
      &::after {
        position: absolute;
        content: '';
        width: 10px;
        height: 6px;
        background-color: transparent;
        right: 3px;
        bottom: 5px;
        border-left: 2px solid #fff;
        border-bottom: 2px solid #fff;
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
      .fillRuleListMoney {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  //充值规则详情
  .RuleListdetail {
    display: flex;
    align-items: center;
    margin: 5px 0 0px 10px;
    height: 28px;
    ::v-deep .el-form-item {
      margin-bottom: 0;
    }
    //指定商品
    .appointGoods {
      height: 28px;
      line-height: 28px;
      margin-left: 20px;
    }
    //赠送金额多选框
    .giveMoneyRadio {
      margin: 0 20px;
    }
    //多选框
    .el-checkbox {
      margin-right: 0;
    }
    span {
      font-size: 14px;
    }
    //充值规则详情
    .fillRuleinput {
      width: 150px;
      margin-left: 20px;
    }
    //赠送金额
    .fillRuleinputMoney {
      width: 150px;
      margin-right: 10px;
      margin-left: 10px;
    }

    //详情标题
    .checkboxTitle {
      margin-left: 7px;
      margin-right: 5px;
    }
  }
}
.mt10 {
  margin-top: 10px !important;
}
.ml20 {
  margin-left: 20px !important;
}
.ml30 {
  margin-left: 30px !important;
}
.mt20 {
  margin-bottom: 20px !important;
  // margin-button: 30px;
}
.mt0 {
  margin-bottom: 0px !important;
  // margin-button: 30px;
}
.text {
  color: rgb(153, 153, 153);
  font-size: 12px;
}

.mar20 {
  margin: 10px !important;
}
.marT10 {
  margin-top: 10px;
}
.text {
  color: rgb(153, 153, 153);
  font-size: 12px;
}
</style>
