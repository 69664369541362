<template>
    <!-- 基本信息区域 -->
    <div class="basicInfo">
        <div class="x-f">
            <el-form-item label="活动编号" :prop="`${seckill ? 'seckillBody' : 'fightGroupBody'}.activityNo`"
                label-width="120px">
                <el-input v-model="form.activityNo" disabled placeholder="自动生成"></el-input>
            </el-form-item>
            <el-form-item label="活动名称" :prop="`${seckill ? 'seckillBody' : 'fightGroupBody'}.activityName`"
                label-width="120px" :rules="rules.activityName">
                <el-input v-model="form.activityName" :disabled='disabled'></el-input>
            </el-form-item>
        </div>
        <div class="x-f">
            <el-form-item label="活动日期" label-width="120px"
                :prop="`${seckill ? 'seckillBody' : 'fightGroupBody'}.activityTimes`" :rules="rules.activityTimes">
                <el-date-picker  :picker-options="forbiddenTime" :disabled='disabled' unlink-panels v-model="form.activityTimes" type="datetimerange" range-separator="至"
                    start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']">
                </el-date-picker>
            </el-form-item>
            <!--            <el-form-item-->
            <!--                label="适用渠道"-->
            <!--                prop="AllowOfflineShop"-->
            <!--                label-width="120px"-->
            <!--            >-->
            <!--                <el-radio v-model="form.usingChannels" label="0">线上商城</el-radio>-->
            <!--            </el-form-item>-->
        </div>
        <!-- 砍价页面专用 -->
        <div class="bargain" v-if="isBargain">
            <el-form-item label="砍价有效期" label-width="120px" class="validity">
                <div class="x-f">
                    <el-form-item>
                        <el-input></el-input>
                        天
                    </el-form-item>
                    <el-form-item>
                        <el-input></el-input>
                        时
                    </el-form-item>
                    <el-form-item>
                        <el-input></el-input>
                        分
                    </el-form-item>
                </div>
                <div class="tag">
                    砍价有效期需在15分钟至15天之间,若设置1天,用户发起砍价需要在24小时内邀请好友砍价成功,超时则砍价失败
                </div>
            </el-form-item>
            <el-form-item label="砍价成功人数" label-width="120px" class="validity">
                <el-input-number :disabled='disabled' v-model="form.num" controls-position="right" :min="1"></el-input-number>
                <div class="tag">
                    砍价助力人数达到该人数后会通知用户砍价成功,请支付下单
                </div>
            </el-form-item>
            <el-form-item label="首刀砍价比例(%)" label-width="140px" class="validity">
                <el-input></el-input>
                <div class="tag">
                    此处为买家发起砍价时自己首刀砍价比例,建议设的更高一点,提高买家砍价兴趣
                </div>
                <div class="tag">
                    例: 商品售价为¥100,首刀比例为90%,则发起砍价时买家可以自己砍¥90
                </div>
            </el-form-item>
        </div>
        <el-form-item label="支付方式" class="payWay" label-width="120px"
            :prop="`${seckill ? 'seckillBody' : 'fightGroupBody'}.payChannelItems`" :rules="rules.payChannelItems">
            <el-checkbox-group :disabled='disabled' v-model="form.payChannelItems">
                <el-checkbox :label="0">微信支付</el-checkbox>
                <el-checkbox :label="1">会员卡支付</el-checkbox>
            </el-checkbox-group>
        </el-form-item>
        <el-form-item label="配送方式" class="disWay" label-width="120px"
            :prop="`${seckill ? 'seckillBody' : 'fightGroupBody'}.fetchTypeItems`" :rules="rules.fetchTypeItems">
            <el-checkbox-group :disabled='disabled' v-model="form.fetchTypeItems">
                <el-checkbox :label="1">门店自提</el-checkbox>
                <el-checkbox :label="2">同城配送</el-checkbox>
                <el-checkbox :label="3">快递配送</el-checkbox>
            </el-checkbox-group>
        </el-form-item>
        <!-- 自定义方式区域 -->
        <div class="selectDiyWay" v-if="form.deliveryType == 1000">
            <el-form-item class="disWay" label-width="120px">
                <el-checkbox-group :disabled='disabled' v-model="form.fetchTypeItems">
                    <el-checkbox :label="1">门店自提</el-checkbox>
                    <el-checkbox :label="2">同城配送</el-checkbox>
                    <el-checkbox :label="3">快递配送</el-checkbox>
                </el-checkbox-group>
            </el-form-item>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BasicInfo',
    props: {
        //表单数据
        form: {
            type: Object,
            default: () => ({})
        },
        seckill: {
            type: Boolean,
            default: false
        },
        //是否显示砍价区域
        isBargain: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            forbiddenTime: {
              //禁用当前日期之前的日期
             disabledDate(time) {
             //Date.now()是javascript中的内置函数，它返回自1970年1月1日00:00:00 UTC以来经过的毫秒数。
             return time.getTime() < Date.now() - 8.64e7;
           },
         },
            rules: {
                'activityName': [
                    { required: true, message: '请输入活动名称', trigger: ['blur', 'change'] }
                ],
                'activityTimes': [
                    { required: true, message: '请选择活动日期', trigger: ['change', 'blur'] }
                ],
                'payChannelItems': [
                    { required: true, message: '请选择支付方式', trigger: ['change', 'blur'] }
                ],
                'fetchTypeItems': [
                    { required: true, message: '请选择配送方式', trigger: ['change', 'blur'] }
                ]
            }
        }
    },
    methods: {
        // handleCheckedCitiesChange(val) {
        //     console.log('----', val)
        //     this.form.fetchType = val
        // },
        // payChange(val) {
        //     console.log('-3---', val,)
        //     this.form.payChannelItems = val
        // }
    }
}
</script>

<style lang="scss" scoped>
.basicInfo {
    .el-form-item {
        width: 530px;
        margin-right: 10px;
        margin-bottom: 10px;

        ::v-deep .el-date-editor--datetimerange.el-input__inner {
            width: 410px;
        }
    }

    .payWay,
    .disWay,
    .rangeShop,
    .rangeTime {
        margin-bottom: 10px;
    }

    //砍价有效期
    .validity {
        width: 100%;

        .el-form-item {
            width: 125px;

            .el-input {
                width: 100px;
                margin-right: 10px;
            }
        }

        .el-input,
        .el-input-number {
            width: 100px;
        }
    }

    //提示的文字
    .tag {
        margin-bottom: 0;
        color: #a4a5a6;
        line-height: 25px;
    }
}
</style>
