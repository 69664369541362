<template>
  <!-- 拼团详情页 -->
  <div class="fightGroup-detail" v-loading="loading">
    <topOperatingButton
      :showAudit="false"
      :isAddBtn="false"
      :isExamineBtn="false"
      :isAuditBillBtn="false"
      :isSubmitAddBtn="false"
      @submitForm="save"
      @getQuit="getQuit"
      id="topOperatingButton"
      :disabled="disabled"
    >
    </topOperatingButton>
    <!--  @addBill="addBill"
      @auditBill="auditBill"
      @handleAdd="handleAdd" -->
    <!-- 头部定位区域 -->
    <!-- <head-tool>
      <template #left>
        <div class="headTitle">{{ `拼团` }}</div>
      </template>
      <template #right>
        <el-button type="primary" size="mini" @click="save">保存</el-button> -->
    <!--                <el-button type="primary" size="mini" @click="backSave">保存并退出</el-button>-->
    <!--                <el-dropdown split-button size="mini" @click="handleBtn('审核')"-->
    <!--                             @command="handleBtn('反审核')">-->
    <!--                    审核-->
    <!--                    <el-dropdown-menu slot="dropdown">-->
    <!--                        <el-dropdown-item>反审核</el-dropdown-item>-->
    <!--                    </el-dropdown-menu>-->
    <!--                </el-dropdown>-->
    <!-- <el-button size="mini" @click="quit">退出</el-button> -->
    <!-- </template> -->
    <!-- </head-tool> -->
    <div class="main">
      <el-form label-width="70px" :model="form" ref="formRef" :rules="rules">
        <ComCard :title="`基本信息`">
          <template #content>
            <basicInfo
              ref="basicInfo"
              class="content"
              :form="form.fightGroupBody"
              :disabled="disabled"
            ></basicInfo>
          </template>
        </ComCard>
        <ComCard :title="`参与活动的商品`">
          <template #content>
            <div class="goods content">
              <ETable
                :data="form"
                activityType="101"
                :tableColumnLabel="goodsTableLabel"
                @selectGood="selectGood"
                @tableRow="tableRow"
                @deleteGood="deleteGood"
                @search="eTableSearch"
                @pitchGoods="pitchGoods"
               :disabled="disabled"
              >
              </ETable>
            </div>
          </template>
        </ComCard>
        <!-- 更多配置 -->
        <ComCard :title="`更多配置`">
          <template #content>
            <div class="moreConfig content">
              <el-form-item label="优惠叠加" label-width="100px">
                <el-checkbox v-model="form.fightGroupBody.isEnableCoupon" :disabled="disabled"
                  >允许使用优惠券
                </el-checkbox>
              </el-form-item>
              <el-form-item
                label="成团有效期"
                label-width="100px"
                prop=""
                :rules="rules[`fightGroupBody.expirationDate`]"
              >
                <SelectDayTime
                  :expirationDate="form.fightGroupBody.expirationDate"
                  @selectDay="selectDay"
                  :disabled="disabled"
                />
              </el-form-item>
              <el-form-item
                label="适用门店"
                class="rangeShop"
                label-width="100px"
                prop="fightGroupBody.usingInStoreType"
                :rules="rules[`fightGroupBody.usingInStoreType`]"
              >
                <el-radio-group v-model="form.fightGroupBody.usingInStoreType" :disabled="disabled">
                  <el-radio
                    :label="dict.value"
                    v-for="dict in dict.type.fill_shop_range_type"
                    :key="dict.value"
                    :value="dict.value"
                    >{{ dict.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
              <!-- 指定门店区域 -->
              <div
                class="selectShop"
                v-if="
                  form.fightGroupBody.usingInStoreType === '1' ||
                  form.fightGroupBody.usingInStoreType === '2'
                "
              >
                <div class="shopBtn marB10">
                  <el-button size="mini" @click="openShopDialog" :disabled="disabled">选择门店</el-button>
                </div>
                <!-- 门店表格 -->
                <comTable
                  class="marB10"
                  :columnLabel="shopTableLable"
                  :tableData="form.shopItems"
                  @delTableRow="delShopRow"
                  :disabled="disabled"
                ></comTable>
              </div>
              <el-form-item
                label="适用时间"
                class="rangeTime"
                label-width="100px"
                prop="fightGroupBody.usingInTimeType"
                :rules="rules[`fightGroupBody.usingInTimeType`]"
              >
                <el-radio-group v-model="form.fightGroupBody.usingInTimeType" :disabled="disabled">
                  <el-radio
                    :label="dict.value"
                    v-for="dict in dict.type.use_time_range_type"
                    :key="dict.value"
                    :value="dict.value"
                    >{{ dict.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
              <div class="timeTable" v-if="form.fightGroupBody.usingInTimeType === '2'">
                <div class="btn x-f marB10">
                  <el-button size="mini" @click="openDateDialog" :disabled="disabled">选择时间</el-button>
                </div>
                <!-- 时间表格 -->
                <comTable
                  class="marB10"
                  :columnLabel="timeTableLable"
                  :tableData="form.specialDateItems"
                  @delTableRow="delTimeRow"
                ></comTable>
              </div>
              <el-form-item label="限购数" label-width="100px">
                <el-checkbox v-model="form.fightGroupBody.isEnablePurchaseLimit" :disabled="disabled"
                  >开启</el-checkbox
                >
              </el-form-item>
              <el-form-item v-if="form.fightGroupBody.isEnablePurchaseLimit">
                <el-radio-group
                  v-model="form.fightGroupBody.limitedType"
                  class="purchaseLimit x-f"
                  :disabled="disabled"
                >
                  <div class="x-f">
                    <el-radio :label="1">活动限购数</el-radio>
                    <!-- 显示 -->
                    <el-form-item v-if="!(form.fightGroupBody.limitedType == '1')">
                      <el-input disabled></el-input>
                    </el-form-item>
                    <!-- 实际输入 -->
                    <el-form-item v-if="form.fightGroupBody.limitedType == '1'">
                      <el-input
                       :disabled="disabled"
                        v-model="form.fightGroupBody.activityLimitedQty"
                        onkeyup="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div class="x-f">
                    <el-radio :label="2">每日限购数</el-radio>
                    <!-- 显示 -->
                    <el-form-item v-if="!(form.fightGroupBody.limitedType == '2')">
                      <el-input disabled></el-input>
                    </el-form-item>
                    <!-- 实际输入 -->
                    <el-form-item v-if="form.fightGroupBody.limitedType == '2'">
                      <el-input
                       :disabled="disabled"
                        v-model="form.fightGroupBody.dailyLimitedQty"
                        onkeyup="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''"
                      ></el-input>
                    </el-form-item>
                  </div>
                </el-radio-group>
                <div class="tag">
                  活动限购数:当限购数为1时,所有活动商品每个买家只能购买1个商品:适用于低价抢购回馈客户的,限制每个客户只能抢购1个便宜的商品.
                </div>
                <div class="tag">
                  每日限购数:当限购数为1时,所有活动商品每个买家每天只能购买1个商品:第2天可以再次购买1个,适用于低价吸引顾客复购,增加商城活跃度.
                </div>
              </el-form-item>
              <el-form-item label="预热" label-width="100px">
                <el-checkbox v-model="form.fightGroupBody.isEnablePreHeat" :disabled="disabled"
                  >开启</el-checkbox
                >
              </el-form-item>
              <el-form-item class="preheat" v-if="form.fightGroupBody.isEnablePreHeat">
                <div class="tag">
                  开启后,活动会提前展示;例如活动在18:00开始,提前6个小时展示,拼团活动则会在12:00展示在小程序端
                </div>
                提前
                <el-input
                :disabled="disabled"
                  v-model="form.fightGroupBody.beforeQtyHour"
                  onkeyup="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''"
                ></el-input>
                小时展示
              </el-form-item>
              <el-form-item label="每日库存更新" label-width="100px">
                <el-switch v-model="form.fightGroupBody.dailyStockUpdates" :disabled="disabled"> </el-switch>
                <div class="tag">
                  开启后,促销库存每天00:00会初始化为初始库存;例:促销库存为100件,今日售卖50件,剩余库存为50件;0点后,剩余库存会初始化为100件;
                </div>
              </el-form-item>
            </div>
          </template>
        </ComCard>
      </el-form>
    </div>

    <!-- 选择商品弹窗 -->
    <SelectGoods
      v-if="!loading"
      :queryCode="queryCode"
      :isShopp="true"
      :OpenGoods.sync="showGoodsDia"
      @getGoodsIds="pitchGoods"
    />
    <!-- 选择门店弹框 -->
    <SelectShop
      v-if="!loading"
      :showShopDialog="showShopDialog"
      @getShop="selectShop"
      @close="showShopDialog = false"
    >
    </SelectShop>
    <!-- 选择时间 -->
    <selectDate
      ref="selectDate"
      :OpenDate.sync="showDateDialog"
      @changeDate="changeDate"
    />
    <Dialog ref="tablePage" :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import Dialog from "@/components/Dialog";
import topOperatingButton from "@/views/components/topOperatingButton"; //顶部按钮
import comTable from "@/views/components/com-table"; //基本表格组件
import ETable from "../components/e-table.vue"; //可编辑商品表格组件
import basicInfo from "@/views/O2OMall/marketing/components/basicInfo.vue";
import { getBillNo } from "@/api/codeRule"; //基本信息区域组件
import SelectGoods from "@/views/O2OMall/marketing/components/SelectGoods.vue"; //选择商品弹窗组件
import SelectDayTime from "@/views/O2OMall/components/selectDayTime.vue";
import SelectShop from "@/views/O2OMall/components/selectShop.vue";
import SelectDate from "@/views/O2OMall/components/selectDate.vue";
import { categoryList } from "@/api/O2OMall/goods/category";
import {
  fightGroupUpdateGroupSave,
  fightGroupUpdate,
  fightGroupGetDetail,
  groupVerificationActivityGoods,
  fightGroupAudit,
} from "@/api/O2OMall/marketing/fightGroup";
import { deepCopy } from "@/utils";
const form = {
  fightGroupBody: {
    activityNo: "",
    activityName: "", // 活动名称
    activityTimes: [], // 开始结束时间
    usingChannels: "1", // 适用渠道
    payChannelItems: [0, 1], //支付方式
    // deliveryType: '0', // 配送方式
    fetchTypeItems: [1, 2], // 取货方式
    limitedType: "", // 限购类型
    dailyLimitedQty: "", // 每日限购数
    isEnablePurchaseLimit: false, // 是否限购
    activityLimitedQty: "", // 活动限购数
    isEnableCoupon: false, // 优惠叠加
    usingInStoreType: "0", // 适用门店
    usingInTimeType: "0", // 适用时间
    isEnablePreHeat: false, // 是否预热
    beforeQtyHour: "", // 提前XX小时展示
    dailyStockUpdates: false, //  每日库存更新
    expirationDate: "1:0:0", // 成团有效期
  },
  // shopRangeType: '0', //适用门店
  goodsItems: [{}], // 商品表格数据
  shopItems: [], // 关联的门店
  specialDateItems: [], // 自定义时间
};
export default {
  name: "FightGroupDetail",
  dicts: [
    "fill_shop_range_type", //适用门店范围
    "use_time_range_type", //适用时间范围
  ],
  components: {
    comTable,
    Dialog,
    ETable,
    basicInfo,
    SelectGoods,
    SelectShop,
    SelectDate,
    SelectDayTime,
    topOperatingButton,
  },
  data() {
    return {
      dialogOptions: {
        curType: "", //当前类型(门店/会员/商品/类别/品牌)
        title: "",
        width: 1250,
        show: false,
        type: "TreeAndTable",
      },
      loading: false,
      disabled: false,
      tableIndex: -1,
      queryCode: "",
      value: "",
      showGoodsDia: false, // 选择商品弹框
      treeData: [], // 商品弹框分类树列表数据
      showShopDialog: false, // 选择门店弹框
      showDateDialog: false, // 选择时间弹框
      //门店表格字段
      shopTableLable: [
        { label: "编号", prop: "shopNo" },
        { label: "名称", prop: "shopName" },
      ],
      //时间范围表格字段
      timeTableLable: [
        { label: "时间范围", prop: "dateTime", minWidth: "100", align: "left" },
      ],
      datetime: [], //拼团日期
      //商品可编辑表格字段
      goodsTableLabel: [
        { label: "商品名称", prop: "goodsName" },
        { label: "规格", prop: "goodsSpec" },
        { label: "商品条码", prop: "barcode" },
        { label: "单位", prop: "unitName" },
        {
          label: "团购价",
          prop: "fightGroupPrice",
          edit: true,
          precision: 2,
          min: 0,
          max:99999999
        },
        {
          label: "团长价",
          prop: "fightGroupHeadPrice",
          edit: true,
          precision: 2,
          min: 0.01,
          max:99999999
        },
        {
          label: "活动库存",
          prop: "activityStockQuantity",
          edit: true,
          precision: 0,
          min: 0,
          max:99999999
        },
        {
          label: "起购数",
          prop: "minPurchaseQuantity",
          edit: true,
          precision: 0,
          min: 0,
          max:99999999
        },
        {
          label: "成团人数",
          prop: "groupSize",
          edit: true,
          precision: 0,
          min: 0,
          max:99999999
        },
        { label: "售价", prop: "salePrice" },
      ],
      //表单
      form: deepCopy(form),
      rules: {
        // 'fightGroupBody.isEnableCoupon': [
        //     { required: true, message: '是否允许使用优惠券', trigger: ['blur', 'change'] }
        // ],
        "fightGroupBody.usingInStoreType": [
          {
            required: true,
            message: "请选择适用门店",
            trigger: ["blur", "change"],
          },
        ],
        "fightGroupBody.usingInTimeType": [
          {
            required: true,
            message: "请选择适用时间",
            trigger: ["blur", "change"],
          },
        ],
        // 'fightGroupBody.isEnablePurchaseLimit': [
        //     { required: true, message: '是否开启限购', trigger: ['blur', 'change'] }
        // ],
        // 'fightGroupBody.isEnablePreHeat': [
        //     { required: true, message: '是否开启预热', trigger: ['blur', 'change'] }
        // ],
        "fightGroupBody.expirationDate": [
          {
            required: true,
            message: "请选择成团有效期",
            trigger: ["blur", "change"],
          },
        ],
      },
      fightGroupId: "", // 活动ID，编辑用
      isBack: false, // 是否退出
      timeId: undefined,
      onCreated: false,
    };
  },
  activated() {
    if (!this.loading) {
      this.form = deepCopy(form);
      this.$nextTick(() => {
        try {
          this.$refs.formRef.clearValidate();
        } catch (error) {}
        this.init();
      });
    }
  },
  created() {
    this.loading = true;
    this.$nextTick(() => {
      this.loading = false;
      this.init();
    });
  },
  methods: {
    getQuit() {
      this.$tab.closeOpenPage({ path: "/O2OMall/marketing/fightGroup" });
    },
    init() {
      if (this.$route.query.fightGroupId) {
        this.fightGroupId = this.$route.query.fightGroupId;
        this.getDetails();
      } else {
        this.getBillno();
      }
      this.timeId = Date.now(); //获取日期时间识别id
    },
    getDetails() {
      this.loading = true;
      fightGroupGetDetail({
        fightGroupId: this.fightGroupId,
      })
        .then((res) => {
          console.log("详情：", res.data);
         this.func(res.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    func(obj = {}){
       let { fightGroupBody, goodsItems, shopItems, specialDateItems } = obj;
          this.form.fightGroupBody = fightGroupBody;
          this.form.fightGroupBody.expirationDate =
            fightGroupBody.expirationDate == null
              ? "1:0:0"
              : fightGroupBody.expirationDate;
          this.form.goodsItems = goodsItems.length
            ? goodsItems.map((x) => ({
                ...x,
                fightGroupHeadPrice: x.fightGroupHeadPrice || undefined,
              }))
            : [{}];
          this.form.shopItems = shopItems.length ? shopItems : [];
          this.form.specialDateItems = specialDateItems.length
            ? this.$refs.selectDate.setAndGetData(specialDateItems)
            : [];
        this.disabled = this.form.fightGroupBody.auditStatus == 1 ? true : false;
    },
    changeDate(e) {
      this.form.specialDateItems = e;
    },
    async getBillno() {
      getBillNo(180201).then((res) => {
        this.form.fightGroupBody.activityNo = res;
        console.log("2313123:", this.form.fightGroupBody.activityNo);
      });
    },
    // 打开选择商品弹框
    selectGood() {
      categoryList().then((res) => {
        this.treeData = this.recursiveToTree(res.data, 0);
        this.showGoodsDia = true;
      });
    },
    // 行操作
    tableRow(obj) {
      if (obj.type === "push") {
        this.form.goodsItems.push({});
      } else {
        if (this.form.goodsItems.length > 1) {
          this.form.goodsItems.splice(obj.index, 1);
        } else {
          this.form.goodsItems = [{}];
        }
      }
    },
    // 校验后删除不符合的商品
    deleteGood(goodsId) {
      this.form.goodsItems.forEach((item, index) => {
        if (item.goodsId == goodsId) {
          if (this.form.goodsItems.length > 1) {
            this.form.goodsItems.splice(index, 1);
          } else {
            this.form.goodsItems = [{}];
          }
        }
      });
    },
    // 回车搜索商品
    eTableSearch(response, index) {
      if (Array.isArray(response.rows) && response.rows.length) {
        if (response.rows.length > 1) {
          this.tableIndex;
          this.queryCode = response.goodsNo;
          this.$nextTick(this.selectGood);
          this.form.goodsItems[index].goodsNo = this.form.goodsItems[index].originGoodsNo;
        } else {
          this.pitchGoods(response.rows, index);
        }
      }
    },
    // 列表转换树形列表
    recursiveToTree(data, parentId) {
      const tree = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].parentId == parentId) {
          const node = {
            id: data[i].categoryId,
            label: data[i].categoryName,
            children: this.recursiveToTree(data, data[i].categoryId),
          };
          tree.push(node);
        }
      }
      return tree;
    },
    // 选择商品
    pitchGoods(data, index = -1) {
      const arr = deepCopy(this.form.goodsItems);
      const isSplice = index >= 0;
      data.forEach((x, xidx) => {
        x.units
          ?.filter((y) => Number(y.status) === 0)
          ?.forEach?.((item, yidx) => {
            if (isSplice && xidx === 0 && yidx === 0) {
              // arr[index] = deepCopy({ ...x, ...item, originGoodsNo: x.goodsNo })
              arr.splice(index, 1, deepCopy({ ...x, ...item, originGoodsNo: x.goodsNo }));
              const oldItem = arr.find(
                (z, i) =>
                  z.goodsId === x?.goodsId && z.unitId === item?.unitId && i !== index
              );
              if (oldItem) {
                oldItem.goodsId = null;
              }
            } else {
              if (!arr.find((y) => y.goodsId === x.goodsId && y.unitId === item.unitId)) {
                arr.push(deepCopy({ ...x, ...item, originGoodsNo: x.goodsNo }));
              }
            }
          });
      });
      this.form.goodsItems = arr.filter((x, i) => x.units?.length && x.goodsId);
    },
    handleEvent(e, row) {
      const data = row.formData.table.check;
      this.form.shopItems = [
        ...this.form.shopItems,
        ...data
          .filter((x) => !this.form?.shopItems?.find((y) => y.shopId === x.shopId))
          .map((x) => ({
            shopId: x.shopId,
            shopName: x.shopName,
            shopNo: x.shopNo,
          })),
      ].filter(
        (item) =>
          (!(JSON.stringify(item) === "{}") && !(item.shopNo === undefined)) ||
          !(item.shopNo === undefined)
      );
    },
    //打开选择门店弹框
    openShopDialog() {
      // handleEvent('openShopDialog', { curTable: 'shopOptions' })
      this.dialogOptions = {
        title: "选择门店",
        width: 1250,
        show: true,
        type: "TreeAndTable",
        formData: this.$dialog({ key: "o2oShop" }),
      };
      // this.showShopDialog = true;
    },
    //  选择门店
    selectShop(data) {
      this.form.shopItems = this.form.shopItems.filter(
        (item) =>
          (!(JSON.stringify(item) === "{}") && !(item.shopNo === undefined)) ||
          !(item.shopNo === undefined)
      );
      data.forEach((item) => {
        this.form.shopItems.push({
          shopId: item.shopId,
          shopName: item.shopName,
          shopNo: item.shopNo,
        });
      });
      this.showShopDialog = false;
    },
    // 删除已选门店
    delShopRow(row) {
      this.form.shopItems.forEach((item, index) => {
        if (item.shopId == row.shopId) {
          this.form.shopItems.splice(index, 1);
        }
      });
    },
    // 打开时间选择弹框
    openDateDialog() {
      this.showDateDialog = true;
    },
    save() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
        if (this.form.fightGroupBody.usingInTimeType == 2 && this.form.specialDateItems.length == 0) return this.$message.warning("请选择适用时间！");
          console.log("活动名称", this.form.fightGroupBody.activityName);
          if (this.form.fightGroupBody.activityName == "") {
            this.$message.warning("请输入活动名称");
            return;
          }
          if (!this.form.goodsItems[0].goodsNo) {
            return this.$message.warning("请添加活动商品");
          }
          this.loading = true;
          this.form.fightGroupBody.usingChannels = "1";
          if (
            this.form.fightGroupBody.fightGroupId == undefined ||
            this.form.fightGroupBody.fightGroupId == "undefined"
          ) {
            fightGroupUpdateGroupSave(this.form)
              .then((res) => {
                this.$message.success("保存成功");
                this.func(res.data);
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            fightGroupUpdate(this.form)
              .then((res) => {
                this.$message.success("修改成功");
                this.func(res.data);
              })
              .finally(() => {
                this.loading = false;
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 退出
    quit() {
      this.$confirm("还未保存是否在退出前保存当前内容？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.save();
        })
        .catch(() => {
          this.refresh();
          this.$router.go(-1);
        });
    },
    // 审核/反审核
    handleBtn(type) {
      if (type === "审核") {
        this.auditFun(1);
      } else if (type === "反审核") {
        this.auditFun(0);
      }
    },
    auditFun(status) {
      fightGroupAudit({
        auditStatus: status,
        fightGroupIds: [this.form.fightGroupBody.fightGroupId],
      }).then((res) => {
        this.$message.success("操作成功");
        this.getList();
      });
    },
    // 保存并退出
    backSave() {
      this.isBack = true;
      this.save();
    },
    // 清空数据
    refresh() {
      this.form = deepCopy(form);
      try {
        this.$refs.formRef.clearValidate();
      } catch (error) {}
      this.getBillno();
    },
    delTimeRow(item) {
       this.form.specialDateItems.forEach((time, index) => {
        if (item.columnId == time.columnId) {
          this.form.specialDateItems.splice(index, 1);
        }
      });
    },
    // 选择成团有效期
    selectDay(val) {
      this.form.fightGroupBody.expirationDate = val;
      console.log("拼团时间=======：", this.form.expirationDate);
    },
  },
};
</script>

<style lang="scss" scoped>
.fightGroup-detail {
  background-color: #eaeaea;

  .main {
    padding: 40px 0;
  }

  //卡片内容
  .content {
    padding: 20px 20px;
    padding-top: 0;
  }

  //表单每一项校验提醒的高度
  .el-form-item {
    margin-bottom: 10px;
  }

  //更多配置
  .moreConfig {
    // 限购数
    .purchaseLimit {
      .el-radio {
        margin-right: 0;
      }

      .el-input {
        margin-left: 10px;
        margin-right: 30px;
        width: 100px;
      }
    }

    //预热
    .preheat {
      .el-input {
        width: 100px;
        margin: 0 5px;
      }
    }
  }

  //提示的文字
  .tag {
    margin-bottom: 0;
    color: #a4a5a6;
    line-height: 25px;
  }
}
</style>
